<script lang="ts">
    import { concatClasses } from '../utils'

    let classNames = ''
    export { classNames as class }
    export let size = 48
    export let variant = 'current-color'

    $: classes = concatClasses(['loader', `loader-${variant}`, classNames])
</script>

<svg
    class={classes}
    height={size}
    preserveAspectRatio="xMidYMid"
    viewBox="0 0 100 100"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    {...$$restProps}
>
    <!-- <circle
        class="loader-bg"
        cx="50"
        cy="50"
        r="35"
        stroke-width="10"
        fill="none"
    />
    <circle
        class="loader-fill"
        data-testid="fill"
        cx="50"
        cy="50"
        r="35"
        stroke-width="3"
        stroke-linecap="round"
        fill="none"
        transform="rotate(502.23 50 50)"
    >
        <animateTransform
            attributeName="transform"
            type="rotate"
            repeatCount="indefinite"
            dur="1.665s"
            values="0 50 50;180 50 50;720 50 50"
            keyTimes="0;0.5;1"
        />
        <animate
            attributeName="stroke-dasharray"
            repeatCount="indefinite"
            dur="1.665s"
            values="21.991148575128552 197.92033717615695;109.95574287564277 109.95574287564275;21.991148575128552 197.92033717615695"
            keyTimes="0;0.5;1"
        />
    </circle> -->
    <circle
        class="loader-bg"
        cx="50"
        cy="50"
        fill="none"
        r="38"
        stroke-width="10"
    />
    <circle
        class="loader-fill"
        cx="50"
        cy="50"
        data-testid="fill"
        fill="none"
        r="38"
        stroke-linecap="round"
        stroke-width="3"
        transform="rotate(502.23 50 50)"
    >
        <animateTransform
            attributeName="transform"
            dur="1.665s"
            keyTimes="0;0.5;1"
            repeatCount="indefinite"
            type="rotate"
            values="0 50 50;180 50 50;720 50 50"
        />
        <animate
            attributeName="stroke-dasharray"
            dur="1.665s"
            keyTimes="0;0.5;1"
            repeatCount="indefinite"
            values="25.132741228718345 225.1946710584651;125.66370614359172 125.66370614359172;25.132741228718345 225.1946710584651"
        />
    </circle>
</svg>

<style global lang="postcss">
    .loader.loader-current-color {
        .loader-bg {
            stroke: transparent;
        }

        .loader-fill {
            stroke: currentcolor;
        }
    }
</style>
